import { ButtonBase, Stack, SxProps } from '@mui/material';
import { To } from 'history';
import { Link } from 'react-router-dom';
import LogoIcon from './LogoIcon';
import Logo from './LogoMain';

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: To;
  height?: number;
}

const LogoSection = ({ reverse, isIcon, sx, to, height }: Props) => {
  return (
    <Stack alignItems="center" spacing={2} sx={{ pb: 2 }}>
      <ButtonBase disableRipple component={Link} to={to || '#'} sx={{ ...sx }}>
        {isIcon ? <LogoIcon height={height} /> : <Logo height={height} reverse={reverse} />}
      </ButtonBase>
    </Stack>
  );
};

export default LogoSection;
