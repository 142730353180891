import { ComponentType, LazyExoticComponent, Suspense } from 'react';

import { LinearProgressProps } from '@mui/material/LinearProgress';
import Loader from './Loader';

const Loadable = (Component: LazyExoticComponent<() => JSX.Element> | ComponentType<any>) => (props: LinearProgressProps) => (
  <Suspense fallback={<Loader />}>
    <Component {...props} />
  </Suspense>
);

export default Loadable;
