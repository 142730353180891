import { Avatar, Chip, Icon, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import { ForwardRefExoticComponent, RefAttributes, forwardRef, useEffect, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import { activeItem, openDrawer } from 'store/reducers/menu';
import { LinkTarget, NavItemType } from 'types/menu';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

interface Props {
  item: NavItemType;
  level: number;
}

const NavItem = ({ item, level }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const menu = useSelector((state: any) => state.menu);
  const { drawerOpen, openItem } = menu;

  let itemTarget: LinkTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps: {
    component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string;
    href?: string;
    target?: LinkTarget;
  } = { component: forwardRef((props, ref) => <Link {...props} to={item.url!} target={itemTarget} />) };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const ItemIcon = item.icon!;
  const itemIcon = useMemo(
    () =>
      item.icon ? (
        <ItemIcon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} />
      ) : (
        <Icon fontSize="small">
          <Typography>{item.id && item.id[0].toUpperCase()}</Typography>
        </Icon>
      ),
    [item.icon, drawerOpen, item.id]
  );

  const isSelected = openItem.findIndex((id: any) => id === item.id) > -1;

  const { pathname } = useLocation();

  // active menu item on page load
  useEffect(() => {
    const path_arr = pathname.split('/');
    const item_arr = item.url?.split('/');
    path_arr.pop();

    if (pathname === item.url || _.isEqual(path_arr, item_arr)) {
      dispatch(activeItem({ openItem: [item.id] }));
      if (drawerOpen && isMobile) {
        dispatch(openDrawer({ drawerOpen: !drawerOpen }));
      }
    }
  }, [pathname]);

  const textColor = theme.palette.mode === 'dark' ? 'grey.400' : 'text.primary';
  const iconSelectedColor = theme.palette.mode === 'dark' && drawerOpen ? 'text.primary' : 'primary.main';

  const renderedListItemText = useMemo(
    () => (
      <ListItemText
        primary={
          <Typography
            variant="h6"
            sx={[
              isSelected
                ? {
                    color: iconSelectedColor
                  }
                : {
                    color: textColor
                  }
            ]}
          >
            {item.title}
          </Typography>
        }
      />
    ),
    [isSelected, iconSelectedColor, textColor, item.title]
  );

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      selected={isSelected}
      sx={[
        {
          zIndex: 1201,
          py: !drawerOpen && level === 1 ? 1.25 : 1
        },
        drawerOpen
          ? {
              pl: `${level * 28}px`
            }
          : {
              pl: 1.5
            },
        drawerOpen &&
          ((theme) => ({
            '&:hover': {
              bgcolor: 'primary.lighter',
              ...theme.applyStyles('dark', {
                bgcolor: 'divider'
              })
            },
            '&.Mui-selected': {
              bgcolor: 'primary.lighter',
              borderRight: `2px solid ${theme.palette.primary.main}`,
              color: iconSelectedColor,
              '&:hover': {
                color: iconSelectedColor,
                bgcolor: 'primary.lighter',
                ...theme.applyStyles('dark', {
                  bgcolor: 'divider'
                })
              },
              ...theme.applyStyles('dark', {
                bgcolor: 'divider'
              })
            }
          })),
        !drawerOpen && {
          '&:hover': {
            bgcolor: 'transparent'
          },
          '&.Mui-selected': {
            '&:hover': {
              bgcolor: 'transparent'
            },
            bgcolor: 'transparent'
          }
        }
      ]}
    >
      {itemIcon && (
        <ListItemIcon
          sx={[
            {
              minWidth: 28
            },
            isSelected
              ? {
                  color: iconSelectedColor
                }
              : {
                  color: textColor
                },
            !drawerOpen &&
              ((theme) => ({
                borderRadius: 1.5,
                width: 36,
                height: 36,
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                  bgcolor: 'secondary.lighter',
                  ...theme.applyStyles('dark', {
                    bgcolor: 'secondary.light'
                  })
                }
              })),
            !drawerOpen &&
              isSelected &&
              ((theme) => ({
                bgcolor: 'primary.lighter',
                '&:hover': {
                  bgcolor: 'primary.lighter',
                  ...theme.applyStyles('dark', {
                    bgcolor: 'primary.darker'
                  })
                },
                ...theme.applyStyles('dark', {
                  bgcolor: 'primary.900'
                })
              }))
          ]}
        >
          {itemIcon}
        </ListItemIcon>
      )}
      {(drawerOpen || (!drawerOpen && level !== 1)) && renderedListItemText}
      {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};
export default NavItem;
