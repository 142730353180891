import { useTheme } from '@mui/material/styles';
import logoDark from 'assets/images/logo-dark.png';
import logo from 'assets/images/logo.png';

const LogoMain = ({ reverse, height, ...others }: { reverse?: boolean; height?: number }) => {
  const theme = useTheme();
  return <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Jadi" height={height || 100} />;
};

export default LogoMain;
