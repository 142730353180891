import { MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSwitchRole } from 'api/auth';
import { getClient, getClients } from 'api/clients';
import { AuthContext } from 'contexts/auth';
import { memo, useContext, useMemo } from 'react';

const ClientSelect = memo(() => {
  const { mutate } = useSwitchRole();

  const { user, active, company_id } = useContext(AuthContext);

  const clientId = useMemo(() => (active ? company_id : ''), [active, company_id]);

  const { data: clientsData, isLoading } = useQuery({ ...getClients(), enabled: Boolean(active) });
  const { data: client } = useQuery(getClient(clientId));

  const { data: clients } = clientsData || {};

  const selectEnabled = (user?.is_staff && clients?.length > 0) || (!user?.is_staff && clients?.length > 1);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const data = { company_id: event.target.value };
    mutate(data, {
      onSuccess: () => (window.location.href = '/')
    });
  };

  const memoizedSelect = useMemo(() => {
    return (
      <Select
        fullWidth
        displayEmpty
        id="select-client"
        size="small"
        sx={{ '& .MuiSelect-select': { py: 0.75, px: 1.25 } }}
        value={client?.uid || ''}
        onChange={handleChange}
      >
        <MenuItem disabled value="">
          No Account Selected
        </MenuItem>
        {clients?.map((client: any) => (
          <MenuItem key={client.uid} value={client.uid}>
            {client.name}
          </MenuItem>
        ))}
      </Select>
    );
  }, [clients, client]);

  return (
    <Stack alignItems="center" spacing={2} sx={{ pb: 2 }}>
      {user && !isLoading && (selectEnabled ? memoizedSelect : client && <Typography variant="subtitle1">{client?.name}</Typography>)}
    </Stack>
  );
});

export default ClientSelect;
