import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { getCurrentUser } from 'api/auth';
import Snackbar from 'components/@extended/Snackbar';
import ErrorFallback from 'components/ErrorFallback';
import NetworkStatusIndicator from 'components/NetworkIndicator';
import { AuthContext } from 'contexts/auth';
import useConfig from 'hooks/useConfig';
import useNavigatorOnLine from 'hooks/useNavigatorOnline';
import { Settings } from 'luxon';
import { lazy, Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from 'routes';
import ThemeCustomization from 'themes';

const LinearProgress = lazy(() => import('@mui/material/LinearProgress'));

Settings.throwOnInvalid = true;
Settings.defaultZone = 'Africa/Nairobi';
Settings.defaultLocale = 'en-KE';

const App = () => {
  const { data: currentUser, isLoading } = useQuery(getCurrentUser());
  const defaults = { user: null, active: false, company_id: '', default_role: '', menu: 'top', roles: [], company_type: '' };
  const { user, active, company_id, company_type, default_role, menu, roles } = currentUser || defaults;

  const { onChangeNetwork } = useConfig();

  const { isOnline } = useNavigatorOnLine();

  useEffect(() => {
    onChangeNetwork(isOnline);
  }, [isOnline]);

  if (isLoading) {
    return <Suspense fallback={<LinearProgress />}></Suspense>;
  }

  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback error="" />} showDialog>
      <AuthContext.Provider value={{ user, active, company_id, default_role, company_type, menu, roles }}>
        <ThemeCustomization>
          <NetworkStatusIndicator />
          <RouterProvider router={router} />
          <Snackbar />
        </ThemeCustomization>
      </AuthContext.Provider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
