import { Card, CardContent, CardContentProps, CardHeader, CardHeaderProps, CardProps, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CSSProperties, ReactNode, Ref, forwardRef, useMemo } from 'react';
import { KeyedObject } from 'types/root';

const headerSX = {
  p: 2.5,
  '& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' }
};

export interface MainCardProps extends KeyedObject {
  border?: boolean;
  boxShadow?: boolean;
  children?: ReactNode | string;
  subheader?: ReactNode | string;
  style?: CSSProperties;
  content?: boolean;
  contentSX?: CardContentProps['sx'];
  darkTitle?: boolean;
  divider?: boolean;
  sx?: CardProps['sx'];
  secondary?: CardHeaderProps['action'];
  shadow?: string;
  elevation?: number;
  title?: ReactNode | string;
  codeHighlight?: boolean;
  fullWidth?: boolean;
  modal?: boolean;
}

const MainCard = forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      subheader,
      content = true,
      contentSX = {},
      darkTitle,
      divider = true,
      elevation,
      secondary,
      shadow,
      sx,
      title,
      codeHighlight = false,
      modal = false,
      fullWidth = false,
      ...others
    }: MainCardProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const theme = useTheme();
    boxShadow = theme.palette.mode === 'dark' ? boxShadow || true : boxShadow;

    const memoizedCardHeader = useMemo(() => {
      if (!darkTitle && title) {
        return <CardHeader sx={headerSX} titleTypographyProps={{ variant: 'h3' }} title={title} action={secondary} subheader={subheader} />;
      }
      if (darkTitle && title) {
        return <CardHeader sx={headerSX} title={<Typography variant="h3">{title}</Typography>} action={secondary} />;
      }
      return null;
    }, [darkTitle, title, secondary, subheader, headerSX]);

    return (
      <Card
        elevation={elevation || 0}
        ref={ref}
        {...others}
        sx={[
          ...(Array.isArray(sx) ? sx : [sx]),
          { position: 'relative', borderRadius: 1, borderColor: theme.palette.grey.A800 },
          border
            ? {
                borderWidth: '1px',
                borderStyle: 'solid'
              }
            : {
                border: 'none'
              },
          boxShadow
            ? {
                ':hover': {
                  boxShadow: shadow || theme.customShadows.z1
                }
              }
            : {
                ':hover': {
                  boxShadow: 'inherit'
                }
              },
          codeHighlight
            ? {
                '& pre': {
                  m: 0,
                  p: '12px !important',
                  fontFamily: theme.typography.fontFamily,
                  fontSize: '0.75rem'
                }
              }
            : {},
          modal
            ? {
                position: 'absolute' as const,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: {
                  xs: `calc( 100% - 50px)`,
                  md: fullWidth ? `80%` : `auto`
                },
                '& .MuiCardContent-root': {
                  overflowY: 'auto',
                  minHeight: 'auto',
                  maxHeight: `calc(100vh - 200px)`
                }
              }
            : {},

          (theme) => ({
            boxShadow: boxShadow && (!border || theme.palette.mode === 'dark') ? shadow || theme.customShadows.z1 : 'inherit',
            ...theme.applyStyles('dark', {
              borderColor: theme.palette.divider
            })
          })
        ]}
      >
        {/* card header and action */}
        {memoizedCardHeader}
        {/* content & header divider */}
        {title && divider ? <Divider /> : null}
        {/* card content */}
        {content ? <CardContent sx={contentSX}>{children}</CardContent> : null}
        {!content ? children : null}
      </Card>
    );
  }
);

export default MainCard;
