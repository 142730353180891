import { ButtonProps, Button as MButton } from '@mui/material';
interface SubmitProps {
  isPending?: boolean;
  disabled?: boolean;
}

const Button = ({ isPending, disabled, loading, ...props }: ButtonProps & SubmitProps) => (
  <MButton variant="contained" size="small" disabled={isPending || disabled} loading={isPending || loading} {...props} />
);

export default Button;
