import {
  Box,
  ClickAwayListener,
  Divider,
  Grid2 as Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Button from 'components/@extended/Button';
import Dot from 'components/@extended/Dot';
import Transitions from 'components/@extended/Transitions';
import MainCard from 'components/MainCard';
import { AuthContext } from 'contexts/auth';
import { getMenuItems } from 'layout/MainLayout/menu-items';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

// ==============================|| HEADER CONTENT - MEGA MENU SECTION ||============================== //

const Navigation = () => {
  const theme = useTheme();
  const { user, active, default_role, company_id, company_type } = useContext(AuthContext);
  const menuItems = getMenuItems(user, active, default_role, company_type, company_id);
  const { items } = menuItems;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [open, setOpen] = React.useState('');
  const [current, setCurrent] = React.useState('');

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(event.currentTarget.id);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpen('');
  };

  return (
    <Stack direction="row" sx={{ ml: 2 }}>
      {items?.map((item, idx) => (
        <Box sx={{ flexShrink: 0, ml: 2 }} key={`${idx}`}>
          <Button aria-haspopup="true" onMouseEnter={handlePopoverOpen} onClick={handlePopoverOpen} id={`${idx}`}>
            <Typography color="black" variant="h5">
              {item.title}
              {current === `${idx}` && <Divider sx={{ borderBottomWidth: 5, color: 'blue' }} />}
            </Typography>
          </Button>
          <Popper
            id={`${idx}-popover`}
            placement="bottom-start"
            open={open === `${idx}`}
            anchorEl={anchorEl}
            role={undefined}
            transition
            disablePortal
            popperOptions={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 20]
                  }
                }
              ]
            }}
          >
            {({ TransitionProps }) => (
              <Transitions type="grow" position="top" in={open === `${idx}`} {...TransitionProps}>
                <Paper
                  sx={{
                    boxShadow: theme.customShadows.z1,
                    minWidth: '20rem',
                    width: '100%'
                  }}
                >
                  <ClickAwayListener onClickAway={() => handlePopoverClose()}>
                    <MainCard elevation={0} border={false} content={false}>
                      <Grid container>
                        <Grid
                          size={{
                            md: 12
                          }}
                        >
                          <Box
                            sx={{
                              p: 0.5,
                              '& .MuiList-root': {
                                pb: 0
                              },
                              '& .MuiListSubheader-root': {
                                p: 0,
                                pb: 1
                              },
                              '& .MuiListItemButton-root': {
                                p: 0.5,
                                '&:hover': {
                                  background: 'transparent',
                                  '& .MuiTypography-root': {
                                    color: 'primary.main'
                                  }
                                }
                              }
                            }}
                          >
                            <Grid container spacing={6}>
                              <Grid size={12}>
                                <List component="nav" aria-labelledby="nested-list-user">
                                  {item.children
                                    ?.filter((child) => !!child.url)
                                    .map((subItem, index) => {
                                      return (
                                        <ListItemButton
                                          key={index}
                                          disableRipple
                                          component={Link}
                                          to={subItem.url || ''}
                                          onClick={() => {
                                            setCurrent(`${idx}`);
                                            handlePopoverClose();
                                          }}
                                        >
                                          <ListItemIcon>
                                            <Dot size={7} color="secondary" variant="outlined" />
                                          </ListItemIcon>
                                          <ListItemText primary={subItem.title} />
                                        </ListItemButton>
                                      );
                                    })}
                                </List>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </MainCard>
                  </ClickAwayListener>
                </Paper>
              </Transitions>
            )}
          </Popper>
        </Box>
      ))}
    </Stack>
  );
};

export default Navigation;
